import React from 'react'
import Layout from 'layouts/fr'
import styled, { css } from 'styled-components'
import design from 'design'
import {
  Title,
  Text,
  Row,
  Div,
  Box,
  Button,
  Input,
  Img,
  Calendar, FullWidthBg
} from 'components'
import {TryOrSchedule} from "containers/fr";
import StairlinShip from "images/StairlinShip8bitcopy.png";
import Island from "images/ConversationsCoppice8bitcopy.png";
import avatar from '../images/avatar.png'
import photos2 from '../images/photos2.jpg'
import meetDesktop from '../images/meet-desktop-fr.png'
import BALogo from '../images/ba-logo.png'
import service from '../images/service.jpg'
import BareAddress from '../images/address.svg'
import BareEmail from '../images/email.svg'
import BarePhone from '../images/phone.svg'
import BareWebsite from '../images/website.svg'
import BareArrow from '../images/arrow.inline.svg'
import Moment from "react-moment";
import { format } from 'utils'
import BareDots from "../images/dots.svg";

const formats = format('fr')

const Dots = styled(BareDots)`
  width: 4px;
  height: 24px;
`

const Arrow = styled(BareArrow)`
  width: 8px;
  height: 14px;
  transform: rotate(-90deg);
`

const svgs = css`
  height: 18px;
  width: auto;
`
const Address = styled(BareAddress)`${svgs}`
const Email = styled(BareEmail)`${svgs}`
const Phone = styled(BarePhone)`${svgs}`
const Website = styled(BareWebsite)`${svgs}`

export default () => {
  return (
    <Layout>
      <Box position={'relative'} mb={[6,8]} mt={[4,7]} bg={['transparent','white']} boxShadow={['none','black']} p={[0,6]}>

        <Div display={['none','block']} position={'absolute'} top={-40} left={-20}>
          <img src={StairlinShip} width={231} alt={''} />
        </Div>
        <Div display={['none','block']} position={'absolute'} bottom={-52} right={-32}>
          <img src={Island} width={274} alt={''} />
        </Div>

        <Div maxWidth={['none','55%']} margin={'0 auto'} textAlign={'center'}>
          <Title mb={[2,6]} fontSize={[6,8]}>Atteindre, engager et fidéliser vos clients.</Title>
          <Text mb={[4,6]} fontSize={[3,6]}>Un site web dédié, toutes vos interactions clients réunies dans un seul endroit et un meilleur référencement sur Google.</Text>
          <Div mt={[0,6]} bg={['transparent']}>
            <form action="https://stairlin.com/go" method="get">
              <Div display={['block','flex']}>
                <Input name="email" fontSize={[4,4]} p={[3,4]} placeholder={`Votre email`} /><Button width={['100%','auto']} type={'submit'} bg={'primary'} display={['flex','inline-flex']} color={'white'} boxShadow={'primary'} px={[4,4]} py={[3,4]} fontSize={[4,6]} ml={[0,4]} mt={[3,0]}>Démarrez maintenant</Button>
              </Div>
            </form>
            <Text mt={[4,5]} fontSize={2} color={'#5D6F71'}>Installation simple • Pas de frais initiaux • Pas de carte de crédit requise • Pas de frais de résiliation</Text>
          </Div>
        </Div>
      </Box>

      <Div tag={'section'}>
        <Div display={'flex'} flexWrap={['wrap']} mb={[5,7]}>
          <Title href={'/fr/customers#People'} mr={7} mb={4} fontSize={[5,8]} fontWeight={700}>Clients</Title>
          <Title href={'/fr/customers#Website'} mr={7} mb={4} fontSize={[5,8]} fontWeight={400}>Site web</Title>
          <Title href={'/fr/customers#Conversation'} mr={7} mb={4} fontSize={[5,8]} fontWeight={400}>Téléconsultation</Title>
        </Div>

        <Div id={'People'} display={['block','flex']}>
          <Div flex={1}>
            <Div display={'flex'} flexWrap={['wrap']} mb={[5,7]}>
              <Title href={'/fr/customers#Manage'} mr={6} mb={4} fontSize={[3,4]} fontWeight={700}>Gestion clients</Title>
              <Title href={'/fr/customers#View'} mr={6} mb={4} fontSize={[3,4]} fontWeight={400}>Fiches clients</Title>
              <Title href={'/fr/customers#Remember'} mr={6} mb={4} fontSize={[3,4]} fontWeight={400}>Notes</Title>
            </Div>
            <Div mr={[0,6]} textAlign={['center','left']}>
              <Title mb={[4,6]} fontSize={[6,7]}>Oubliez la saisie de données et établissez des relations durables avec vos clients </Title>
              <Text mb={[4,6]} fontSize={[3,4]}>Stairlin met à jour automatiquement les données de vos clients, ainsi votre liste de contact est toujours à jour</Text>
            </Div>
          </Div>
          <Div mx={[-36,0]} flex={1}>
            <Box boxShadow={'black'} bg={'white'} m={0} p={[4,6]}>
              <Div display={'flex'} justifyContent={'space-between'} alignItems={'flex-start'}>
                <Div mb={6} display={'flex'}>
                  <Div display={'flex'}>
                    <img width={64} height={64} src={avatar} alt=""/>
                    <Div ml={4}>
                      <Text fontSize={6} fontWeight={700}>Leah Dresner</Text>
                      <Text fontSize={3} color={'grayDark'}>Altishausen, Switzerland</Text>
                    </Div>
                  </Div>
                </Div>
                <Div display={['none','block']}>
                  <Button py={2} boxShadow={'primary'} bg={'primary'} color={'white'} fontSize={1}>Nouvelle réservation</Button>
                </Div>
              </Div>

              <Text mb={5} fontSize={[3,4]} fontWeight={700}>Contact</Text>
              <Div display={'flex'} flexWrap={'wrap'} justifyContent={'space-between'}>
                <Div order={1} flexBasis={['50%','33%']} mb={5} _mr={6}>
                  <Text mb={2} fontSize={[2,3]} fontWeight={700}>Téléphone</Text>
                  <Text fontSize={[2,3]}>052 281 18 26</Text>
                </Div>
                <Div order={2} flexBasis={['50%','33%']} mb={5} _mr={6}>
                  <Text mb={2} fontSize={[2,3]} fontWeight={700}>Email</Text>
                  <Text fontSize={[2,3]}>leah@stairlin.ch</Text>
                </Div>
                <Div order={[3,4]} flexBasis={['50%','33%']} mb={5} _mr={6}>
                  <Text mb={2} fontSize={[2,3]} fontWeight={700}>Date de naissance</Text>
                  <Text fontSize={[2,3]}>7 août, 1996</Text>
                </Div>
                <Div order={[4,5]} flexBasis={['50%','33%']} mb={5} _mr={6}>
                  <Text mb={2} fontSize={[2,3]} fontWeight={700}>Langue</Text>
                  <Text fontSize={[2,3]}>Allemand</Text>
                </Div>
                <Div order={[5,3]} flexBasis={['50%','33%']} mb={5} _mr={6}>
                  <Text mb={2} fontSize={[2,3]} fontWeight={700}>Addresse</Text>
                  <Text fontSize={[2,3]}>Bahnhofplatz 95<br/>
                    8573 Altishausen</Text>
                </Div>
                <Div order={6} flexBasis={['50%','33%']} mb={5} _mr={6}>
                  <Text mb={2} fontSize={[2,3]} fontWeight={700}>Référant</Text>
                  <Text fontSize={[2,3]} fontWeight={700} color={'primary'}>Éric Hamilton</Text>
                </Div>
              </Div>

              <Button textAlign={'center'} display={['block','none']} py={2} width={'100%'} boxShadow={'primary'} bg={'primary'} color={'white'} fontSize={1}>Nouvelle réservation</Button>
            </Box>
          </Div>
        </Div>

        <Div display={['block','flex']} flexWrap={'wrap'} mt={[6,8]}>
          <Div mb={6} mr={[0,6]} flex={1}>
            <Title mb={3} mr={[4,6]} fontSize={[2,4]}>Moins de saisie de données</Title>
            <Text fontSize={[2,4]}>Mettez à contribution les réservations en ligne et les paiements pour minimiser le travail et les erreurs de saisie de données.</Text>
          </Div>
          <Div mb={6} mr={[0,6]} flex={1}>
            <Title mb={3} mr={[4,6]} fontSize={[2,4]}>Importez vos contacts</Title>
            <Text fontSize={[2,4]}>Importez tous vos contacts en quelques minutes. Stairlin supporte Outlook, Gmail, vCard et bien plus.</Text>
          </Div>
          <Div mb={6} mr={[0,6]} flex={1}>
            <Title mb={3} mr={[4,6]} fontSize={[2,4]}>Un puissant moteur de recherche</Title>
            <Text fontSize={[2,4]}>Réservations, factures, notes, information client ou encore dates. Retrouvez n’importe quelle information, rapidement.</Text>
          </Div>
        </Div>
      </Div>

      <Div tag={'section'}>
        <Div position={'relative'} p={[36,6]} pt={[6,8]} mx={[-36,-48]} textAlign={'center'} color={design.color.white} bg={design.color.primary}>
          <FullWidthBg />
          <Div justifyContent={['flex-start','center']} display={'flex'} flexWrap={['wrap']} mb={[5,7]}>
            <Title href={'/fr/customers#Manage'} mr={6} mb={4} fontSize={[3,4]} fontWeight={400} color={'white'}>Gestion clients</Title>
            <Title id={'View'} href={'/fr/customers#View'} mr={6} mb={4} fontSize={[3,4]} fontWeight={700} color={'white'}>Fiches clients</Title>
            <Title href={'/fr/customers#Remember'} mr={[0,6]} mb={4} fontSize={[3,4]} fontWeight={400} color={'white'}>Notes</Title>
          </Div>
          <Title mb={[4,5]} fontSize={[6,7]}>Enfin, toutes vos données consolidées dans un seul endroit !</Title>
          <Text mb={[]} fontSize={[3,4]}>Les données clients, le détail des réservations ainsi que les factures sont centralisés et sécurisés </Text>
          <Div textAlign={'left'} mx={[-36,0]} display={['block','flex']} mt={[6,110]}>
            <Div flex={1}>
              <Box boxShadow={'primaryDark'} bg={'white'} m={0} p={[4,6]}>
                <Div display={'flex'} justifyContent={'space-between'} alignItems={'flex-start'}>
                  <Div mb={6} display={'flex'}>
                    <Div display={'flex'}>
                      <img width={64} height={64} src={avatar} alt=""/>
                      <Div color={'text'} ml={4}>
                        <Text fontSize={6} fontWeight={700}>Leah Dresner</Text>
                        <Text fontSize={3} color={'grayDark'}>Altishausen, Switzerland</Text>
                      </Div>
                    </Div>
                  </Div>
                  <Div display={['none','block']}>
                    <Button mr={3} py={2} boxShadow={'black'} bg={'white'} color={'text'} fontSize={1}>Appeler</Button>
                    <Button mr={3} py={2} boxShadow={'black'} bg={'white'} color={'text'} fontSize={1}>Nouvelle facture</Button>
                    <Button py={2} boxShadow={'primary'} bg={'primary'} color={'white'} fontSize={1}>Nouvelle réservation</Button>
                  </Div>
                </Div>

                <Div display={'flex'}>
                  <Div mr={7} display={['none','block']} color={'text'} flex={1}>
                    <Text fontWeight={700} borderBottom={`1px solid ${design.color.grayLight}`} fontSize={3} py={2}>Aperçu</Text>
                    <Text borderBottom={`1px solid ${design.color.grayLight}`} fontSize={3} py={2}>Contact </Text>
                    <Text borderBottom={`1px solid ${design.color.grayLight}`} fontSize={3} py={2}>Réservations</Text>
                    <Text borderBottom={`1px solid ${design.color.grayLight}`} fontSize={3} py={2}>Facturation</Text>
                    <Text borderBottom={`1px solid ${design.color.grayLight}`} fontSize={3} py={2}>Notes</Text>
                  </Div>

                  <Div>
                    <Div color={'text'} flex={5}>
                      <Div display={'flex'} justifyContent={'space-between'}>
                        <Text mb={5} fontSize={[3,4]} fontWeight={700}>Contacts</Text>
                        <Div display={['none','block']}>
                          <Button py={2} boxShadow={'black'} bg={'white'} color={'text'} fontSize={1}>Editer</Button>
                        </Div>
                      </Div>
                      <Div display={'flex'} flexWrap={'wrap'} justifyContent={'space-between'}>
                        <Div flexBasis={['50%','33%']} mb={5} _mr={6}>
                          <Text mb={2} fontSize={[2,3]} fontWeight={700}>Téléphone</Text>
                          <Text fontSize={[2,3]}>052 281 18 26</Text>
                        </Div>
                        <Div flexBasis={['50%','33%']} mb={5} _mr={6}>
                          <Text mb={2} fontSize={[2,3]} fontWeight={700}>Email</Text>
                          <Text fontSize={[2,3]}>leah@stairlin.ch</Text>
                        </Div>
                        <Div flexBasis={['50%','33%']} mb={5} _mr={6}>
                          <Text mb={2} fontSize={[2,3]} fontWeight={700}>Date de naissance</Text>
                          <Text fontSize={[2,3]}>7 août 1996</Text>
                        </Div>
                        <Div flexBasis={['50%','33%']} mb={5} _mr={6}>
                          <Text mb={2} fontSize={[2,3]} fontWeight={700}>Langue</Text>
                          <Text fontSize={[2,3]}>Allemand</Text>
                        </Div>
                        <Div flexBasis={['50%','33%']} mb={5} _mr={6}>
                          <Text mb={2} fontSize={[2,3]} fontWeight={700}>Adresse</Text>
                          <Text fontSize={[2,3]}>Bahnhofplatz 95<br/>
                            8573 Altishausen</Text>
                        </Div>
                        <Div flexBasis={['50%','33%']} mb={5} _mr={6}>
                          <Text mb={2} fontSize={[2,3]} fontWeight={700}>Référant</Text>
                          <Text fontSize={[2,3]} fontWeight={700} color={'primary'}>Éric Hamilton</Text>
                        </Div>
                      </Div>
                    </Div>

                    <Div mt={6} color={'text'} flex={5}>
                      <Div display={'flex'} justifyContent={'space-between'}>
                        <Text mb={5} fontSize={[3,4]} fontWeight={700}>Facturation</Text>
                        <Div display={['none','block']}>
                          <Button py={2} boxShadow={'black'} bg={'white'} color={'text'} fontSize={1}>Facturer maintenant</Button>
                        </Div>
                      </Div>
                      <Div display={'flex'} flexWrap={'wrap'} justifyContent={'space-between'}>
                        <Div flexBasis={['50%','33%']} mb={5} _mr={6}>
                          <Text mb={2} fontSize={[2,3]} fontWeight={700}>Solde Client</Text>
                          <Text fontSize={[5,6]}>CHF 120.00</Text>
                        </Div>
                        <Div flexBasis={['50%','33%']} mb={5} _mr={6}>
                          <Text mb={2} fontSize={[2,3]} fontWeight={700}>En attente</Text>
                          <Text fontSize={[5,6]}>CHF 120.00</Text>
                        </Div>
                        <Div flexBasis={['50%','33%']} mb={5} _mr={6}>
                          <Text mb={2} fontSize={[2,3]} fontWeight={700}>Montant en attente</Text>
                          <Text fontSize={[5,6]}>CHF 0.00</Text>
                        </Div>
                      </Div>
                    </Div>

                    <Div mt={6} color={'text'} flex={5}>
                      <Div display={'flex'} justifyContent={'space-between'}>
                        <Text mb={5} fontSize={[3,4]} fontWeight={700}>Intéractions récentes</Text>
                        <Div display={['none','block']}>
                          <Button py={2} boxShadow={'black'} bg={'white'} color={'text'} fontSize={1}>Intéractions</Button>
                        </Div>
                      </Div>

                      <Div>
                        <Div>
                          <Div display={'flex'}>
                            <Div position={'relative'} width={24} height={24} borderRadius={'50%'} border={`2px solid ${design.color.primary}`}>
                              <Div height={[42,48]} width={'2px'} marginLeft={`-1px`} top={26} left={10} position={'absolute'} borderLeft={`1px solid ${design.color.grayLight}`}></Div>
                              <Div width={20} height={20} borderRadius={'50%'} border={`2px solid ${design.color.white}`}>
                                <Div width={16} height={16} borderRadius={'50%'} bg={`primary`}></Div>
                              </Div>
                            </Div>
                            <Div ml={4} display={'flex'} flex={1} justifyContent={'space-between'}>
                              <Div>
                                <Text fontSize={[2,3]} fontWeight={700}>Facture réglée</Text>
                                <Text fontSize={[1,2]} color={'grayDark'}>IE-18-366-381 for CHF 120.00</Text>
                              </Div>
                              <Div display={['none','block']}>
                                <Text fontSize={3}><Moment subtract={{ d:0,h:4 }} format={formats.MDYH} /></Text>
                              </Div>
                            </Div>
                          </Div>
                        </Div>

                        <Div mt={5}>
                          <Div display={'flex'}>
                            <Div position={'relative'} width={24} height={24} borderRadius={'50%'} border={`2px solid ${design.color.grayLight}`}>
                              <Div height={[42,48]} width={'2px'} marginLeft={`-1px`} top={26} left={10} position={'absolute'} borderLeft={`1px solid ${design.color.grayLight}`}></Div>
                            </Div>
                            <Div ml={4} display={'flex'} flex={1} justifyContent={'space-between'}>
                              <Div>
                                <Text fontSize={[2,3]} fontWeight={700}>CHF 120.00 payé par Leah Dresner</Text>
                                <Text fontSize={[1,2]} color={'grayDark'}>Visa •••• 5791</Text>
                              </Div>
                              <Div display={['none','block']}>
                                <Text fontSize={3}><Moment subtract={{ d:0,h:4 }} format={formats.MDYH} /></Text>
                              </Div>
                            </Div>
                          </Div>
                        </Div>

                        <Div mt={5}>
                          <Div display={'flex'}>
                            <Div position={'relative'} width={24} height={24} borderRadius={'50%'} border={`2px solid ${design.color.grayLight}`}>
                              <Div height={[42,48]} width={'2px'} marginLeft={`-1px`} top={26} left={10} position={'absolute'} borderLeft={`1px solid ${design.color.grayLight}`}></Div>
                            </Div>
                            <Div ml={4} display={'flex'} flex={1} justifyContent={'space-between'}>
                              <Div>
                                <Text fontSize={[2,3]} fontWeight={700}>Facture envoyée</Text>
                                <Text fontSize={[1,2]} color={'grayDark'}>IE-18-366-381 for CHF 120.00</Text>
                              </Div>
                              <Div display={['none','block']}>
                                <Text fontSize={3}><Moment subtract={{ d:0,h:5,m:20 }} format={formats.MDYH} /></Text>
                              </Div>
                            </Div>
                          </Div>
                        </Div>

                        <Div mt={5}>
                          <Div display={'flex'}>
                            <Div position={'relative'} width={24} height={24} borderRadius={'50%'} border={`2px solid ${design.color.grayLight}`}>
                              <Div height={[59,48]} width={'2px'} marginLeft={`-1px`} top={26} left={10} position={'absolute'} borderLeft={`1px solid ${design.color.grayLight}`}></Div>
                            </Div>
                            <Div ml={4} display={'flex'} flex={1} justifyContent={'space-between'}>
                              <Div>
                                <Text fontSize={[2,3]} fontWeight={700}>Elément en attente ajouté</Text>
                                <Text fontSize={[1,2]} color={'grayDark'}>Acupuncture - Première session - 1 heure - CHF 120.00</Text>
                              </Div>
                              <Div display={['none','block']}>
                                <Text fontSize={3}><Moment subtract={{ d:2,h:8,m:30 }} format={formats.MDYH} /></Text>
                              </Div>
                            </Div>
                          </Div>
                        </Div>

                        <Div mt={5}>
                          <Div display={'flex'}>
                            <Div width={24} height={24} borderRadius={'50%'} border={`2px solid ${design.color.grayLight}`}>
                            </Div>
                            <Div ml={4} display={'flex'} flex={1} justifyContent={'space-between'}>
                              <Div>
                                <Text fontSize={[2,3]} fontWeight={700}>Réservation confirmée</Text>
                                <Text fontSize={[1,2]} color={'grayDark'}>Acupuncture - Première sessions - 1 heure - CHF 120.00</Text>
                              </Div>
                              <Div display={['none','block']}>
                                <Text fontSize={3}><Moment subtract={{ d:2,h:8,m:30 }} format={formats.MDYH} /></Text>
                              </Div>
                            </Div>
                          </Div>
                        </Div>
                      </Div>
                    </Div>

                  </Div>

                </Div>
              </Box>
            </Div>
          </Div>

          <Div textAlign={'left'} display={['block','flex']} flexWrap={'wrap'} mt={[6,8]}>
            <Div mb={6} mr={[0,6]} flex={1}>
              <Title mb={3} mr={[4,6]} fontSize={[2,4]}>Une meilleure compréhension de vos clients</Title>
              <Text fontSize={[2,4]}>Notre gestionnaire de clientèle vous permet d’analyser les habitudes de réservation et de paiement de vos clients.</Text>
            </Div>
            <Div mb={6} mr={[0,6]} flex={1}>
              <Title mb={3} mr={[4,6]} fontSize={[2,4]}>Accédez à vos données de partout</Title>
              <Text fontSize={[2,4]}>Toutes vos données dans Stairlin sont automatiquement synchronisées et sécurisées. Accédez-y depuis un navigateur web, sur votre portable, tablette ou smartphone.</Text>
            </Div>
            <Div mb={6} mr={[0,6]} flex={1}>
              <Title mb={3} mr={[4,6]} fontSize={[2,4]}>Vos données sont en sécurité</Title>
              <Text fontSize={[2,4]}>Cette sécurité est assurée par des méthodes de chiffrement aux normes bancaires, le triple stockage et une authentification forte de l’utilisateur.</Text>
            </Div>
          </Div>
        </Div>
      </Div>

      <Div tag={'section'}>
        <Div id={'Remember'} mt={[6,8]}>
          <Div display={['block','flex']} flexDirection={'row-reverse'}>
            <Div flex={1}>
              <Div display={'flex'} flexWrap={['wrap']} mb={[5,7]} justifyContent={['flex-start','flex-end']}>
                <Title href={'/fr/customers#People'} ml={[0,6]} mr={[6,0]} mb={4} fontSize={[5,8]} fontWeight={400}>Clients</Title>
                <Title href={'/fr/customers#Website'} ml={[0,6]} mr={[6,0]} mb={4} fontSize={[5,8]} fontWeight={700}>Site web</Title>
                <Title href={'/fr/customers#Conversation'} ml={[0,6]} mr={[6,0]} mb={4} fontSize={[5,8]} fontWeight={400}>Téléconsultation</Title>
              </Div>
              <Div ml={[0,6]} textAlign={['center','right']}>
                <Title mb={[4,6]} fontSize={[6,7]}>Chaque détail au sujet de vos clients est important</Title>
                <Text mb={[4,6]} fontSize={[3,4]}>Notre outil de notes vous permet de vous souvenir de chaque information clé concernant vos clients.</Text>
              </Div>
            </Div>
            <Div mx={[-48,0]} flex={1}>
              <Box bg={'white'} m={0} p={[4,6]}>
                <Div display={'flex'}>
                  <img width={64} height={64} src={avatar} alt=""/>
                  <Div color={'text'} ml={4}>
                    <Text fontSize={6} fontWeight={700}>Leah Dresner</Text>
                    <Text fontSize={3} color={'grayDark'}>Altishausen, Switzerland</Text>
                  </Div>
                </Div>
                <Div minHeight={200} borderRadius={5} p={3} mt={4} border={`1px solid ${design.color.grayLight}`}>
                  <Text fontSize={3}>Leah a régulièrement des maux de tête aigu. La médication de base ne fait pas effet.</Text>
                </Div>
                <Button mt={4} py={3} boxShadow={'primary'} width={'100%'} bg={'primary'} color={'white'} fontSize={4}>Ajouter une note</Button>
              </Box>
            </Div>
          </Div>
        </Div>
      </Div>

      <Div tag={'section'}>
        <Div id={'Website'} mt={[6,8]}>
          <Div display={'flex'} flexWrap={['wrap']} mb={[5,7]}>
            <Title href={'/fr/customers#People'} mr={7} mb={4} fontSize={[5,8]} fontWeight={400}>Clients</Title>
            <Title href={'/fr/customers#Website'} mr={7} mb={4} fontSize={[5,8]} fontWeight={700}>Site web</Title>
            <Title href={'/fr/customers#Conversation'} mr={7} mb={4} fontSize={[5,8]} fontWeight={400}>Téléconsultation</Title>
          </Div>

          <Div id={'Boost'} display={['block','flex']}>
            <Div flex={1}>
              <Div display={'flex'} flexWrap={['wrap']} mb={[5,7]}>
                <Title href={'/fr/customers#Boost'} mr={[`9px`,6]} mb={4} fontSize={[3,4]} fontWeight={700}>Booster</Title>
                <Title href={'/fr/customers#Grow'} mr={6} mb={4} fontSize={[3,4]} fontWeight={400}>Développer</Title>
              </Div>
              <Div mr={[0,6]} textAlign={['center','left']}>
                <Title mb={[4,6]} fontSize={[6,7]}>Une magnifique visibilité online avec votre mini-site personnalisé</Title>
                <Text mb={[4,6]} fontSize={[3,4]}>Aucune compétence technique requise, votre mini-site est en ligne en quelques minutes</Text>
              </Div>
            </Div>
            <Div mx={[-48,0]} flex={1}>
              <Box bg={'white'} m={0} p={[4,4]}>
                <Div mx={[-4,-4]} mt={[-4,-4]}>
                  <img width={['100%']} src={photos2} alt=""/>
                </Div>
                <Div mt={4} mb={5} display={'flex'} justifyContent={'space-between'}>
                  <Div display={'flex'}>
                    <Img width={[32,64]} height={[32,64]} src={BALogo} alt=""/>
                    <Div ml={4}>
                      <Text fontSize={[0,4]} fontWeight={700}>Blossom Acupuncture</Text>
                      <Text fontSize={[0,3]}>Lausanne - Suisse</Text>
                    </Div>
                  </Div>
                  <Div>
                    <Button py={[2,3]} boxShadow={'primary'} width={'100%'} bg={'primary'} color={'white'} fontSize={[0,4]}>Réserver</Button>
                  </Div>
                </Div>

                <Div ml={4} display={'flex'} flexWrap={'wrap'} justifyContent={'space-between'}>
                  <Div flexBasis={['50%']} mb={5} _mr={6} display={'flex'}>
                    <Div mr={4} pt={`2px`}>
                      <Address/>
                    </Div>
                    <Div>
                      <Text mb={2} fontSize={[0,3]} fontWeight={700}>Addresse</Text>
                      <Text fontSize={[0,2]}>Infinite Loop 1<br/>
                        1003 Lausanne</Text>
                    </Div>
                  </Div>
                  <Div flexBasis={['50%']} mb={5} _mr={6} display={'flex'}>
                    <Div mr={4} pt={`2px`}>
                      <Email/>
                    </Div>
                    <Div>
                      <Text mb={2} fontSize={[0,3]} fontWeight={700}>Email</Text>
                      <Text fontSize={[0,2]}>blossom@stairlin.app</Text>
                    </Div>
                  </Div>
                  <Div flexBasis={['50%']} mb={5} _mr={6} display={'flex'}>
                    <Div mr={4} pt={`2px`}>
                      <Phone/>
                    </Div>
                    <Div>
                      <Text mb={2} fontSize={[0,3]} fontWeight={700}>Téléphone</Text>
                      <Text fontSize={[0,2]}>027 970 69 43</Text>
                    </Div>
                  </Div>
                  <Div flexBasis={['50%']} mb={5} _mr={6} display={'flex'}>
                    <Div mr={4} pt={`2px`}>
                      <Website/>
                    </Div>
                    <Div>
                      <Text mb={2} fontSize={[0,3]} fontWeight={700}>Site web</Text>
                      <Text fontSize={[0,2]}>blossom.ch</Text>
                    </Div>
                  </Div>
                </Div>

              </Box>
            </Div>
          </Div>

          <Div display={['block','flex']} flexWrap={'wrap'} mt={[6,8]}>
            <Div mb={6} mr={[0,6]} flex={1}>
              <Title mb={3} mr={[4,6]} fontSize={[2,4]}>Votre entreprise en ligne</Title>
              <Text fontSize={[2,4]}>Configurez votre page et obtenez une belle visibilité en ligne avec les informations essentielles pour votre entreprise.</Text>
            </Div>
            <Div mb={6} mr={[0,6]} flex={1}>
              <Title mb={3} mr={[4,6]} fontSize={[2,4]}>Version mobile</Title>
              <Text fontSize={[2,4]}>Votre page est optimisée pour toutes les tailles d’écrans. Tant sur tablette que sur smartphone.</Text>
            </Div>
            <Div mb={6} mr={[0,6]} flex={1}>
              <Title mb={3} mr={[4,6]} fontSize={[2,4]}>Un meilleur référencement</Title>
              <Text fontSize={[2,4]}>Notre système est constamment mis à jour pour optimiser le référencement de votre page. Cela pour tous les moteurs de recherche. </Text>
            </Div>
          </Div>
        </Div>
      </Div>

      <Div tag={'section'}>
        <Div position={'relative'} p={[36,8]} mx={[-36,-48]} textAlign={'center'} color={design.color.white} bg={design.color.primary}>
          <FullWidthBg />
          <Div justifyContent={['flex-start','center']} display={'flex'} flexWrap={['wrap']} mb={[5,7]}>
            <Title href={'/fr/customers#Boost'} mr={6} mb={4} fontSize={[3,4]} fontWeight={400} color={'white'}>Booster</Title>
            <Title id={'Grow'} href={'/fr/customers#Grow'} mr={6} mb={4} fontSize={[3,4]} fontWeight={700} color={'white'}>Développer</Title>
          </Div>
          <Title mb={[4,5]} fontSize={[6,7]}>Gérer et développer votre entreprise. En ligne.</Title>
          <Text mb={[]} fontSize={[3,4]}>Peu importe votre domaine d’activité. Faites grandir votre entreprise et augmentez le nombre de vos clients avec notre système de réservation en ligne.</Text>
          <Div display={['block','flex']} mt={[140,110]}>

            <Div mt={[0,`78px`]} mx={[-36,0]} flex={1} mb={6}>
              {/*<Title mb={[4,6]} fontSize={[3,6]}></Title>*/}
              <Box position={'relative'} boxShadow={'primaryDark'} height={['auto',568]} color={'text'} textAlign={'left'} bg={'white'} m={0} mx={[0,4]} p={[4,5]}>
                <Div position={'absolute'} top={-78}>
                  <Div zIndex={1} position={'absolute'} top={0} width={['100%',381]} height={78} boxShadow={'primaryDark'} />
                  <Img zIndex={2} position={'absolute'} top={0} width={['100%',381]} borderRadius={12} src={service} />
                </Div>
                <Div mt={`102px`}>
                  <Text fontWeight={700} mb={[4,5]} mt={[4,4]} fontSize={[4,6]}>Acupuncture</Text>
                </Div>
                <Div display={'flex'}>
                  <Div flex={1}>
                    <Text fontSize={[2,4]} fontWeight={700}>Durée</Text>
                    <Text fontSize={[2,4]}>1 heure</Text>
                  </Div>
                  <Div flex={1}>
                    <Text fontSize={[2,4]} fontWeight={700}>Prix</Text>
                    <Text fontSize={[2,4]}>CHF 120.00</Text>
                  </Div>
                </Div>
                <Text mt={[4,5]} mb={[6,7]} color={'grayDark'} fontSize={3}>
                  Branche de la médecine chinoise traditionnelle consistant à piquer avec des aiguilles en des points précis de la surface du corps d'un patient pour soigner différentes maladies ou provoquer un effet analgésique.
                </Text>
                <Div display={'flex'} justifyContent={'space-between'} mt={[4,`69px`]} alignItems={'center'}>
                  <Div flex={1} mr={4}>
                    <Button width={'100%'} onClick={()=>{}} py={2} height={`48px`} boxShadow={'primary'} bg={'primary'} color={'white'} fontSize={4}>Réserver</Button>
                  </Div>
                  <Div>
                    <Div display={'flex'} justifyContent={'center'} alignItems={'center'} boxShadow={'black'} borderRadius={5} height={`48px`} width={`48px`}>
                      <Dots />
                    </Div>
                  </Div>
                </Div>
              </Box>
            </Div>

            <Div mx={[-48,0]} flex={1}>
              {/*<Title mb={[4,6]} fontSize={[3,6]}></Title>*/}
              <Div px={[0,110]}>
                <Box boxShadow={'primaryDark'} color={'text'} textAlign={'left'} bg={'white'} m={0} pb={1}>
                  <Div display={'flex'} justifyContent={'space-between'} alignItems={'center'} py={`20px`} px={5} borderBottom={`1px solid #CCC`}>
                    <Text fontSize={3} fontWeight={700}>Choisissez un jour et une heure</Text>
                    <Arrow/>
                  </Div>

                  <Calendar lang={'fr'} />

                  <Div mx={4} mt={5}>
                    <Div mb={3} py={2} textAlign={'center'} boxShadow={'black'} fontWeight={700}>09:00 - 10:00</Div>
                    <Div mb={3} py={2} textAlign={'center'} boxShadow={'black'} fontWeight={700}>13:00 - 14:00</Div>
                    <Div mb={3} py={2} textAlign={'center'} boxShadow={'black'} fontWeight={700}>18:00 - 19:00</Div>
                    <Div mb={3} py={2} textAlign={'center'} boxShadow={'black'} fontWeight={700}>Autres créneaux horaires</Div>
                  </Div>
                </Box>
              </Div>
            </Div>
          </Div>
        </Div>
      </Div>

      <Div tag={'section'}>
        <Div id={'Conversation'} mt={[6,8]}>
          <Div display={'flex'} flexWrap={['wrap']} mb={[5,7]}>
            <Title href={'/fr/customers#People'} mr={7} mb={4} fontSize={[5,8]} fontWeight={400}>Clients</Title>
            <Title href={'/fr/customers#Website'} mr={7} mb={4} fontSize={[5,8]} fontWeight={400}>Site web</Title>
            <Title href={'/fr/customers#Conversation'} mr={7} mb={4} fontSize={[5,8]} fontWeight={700}>Téléconsultation</Title>
          </Div>

          <Div id={'Meet'} display={['block','flex']} flexDirection={'row-reverse'}>
            <Div flex={1}>
              <Div ml={[0,6]} textAlign={['center','right']}>
                <Title mb={[4,6]} fontSize={[6,7]}>Une nouvelle manière d'interagir</Title>
                <Text mb={[4,6]} fontSize={[3,4]}>Avec un système de consultation vidéo simple, 100% sécurisé et qui respecte la vie privée.</Text>
              </Div>
            </Div>
            <Div mx={[-48,0]} flex={1}>
              <Box bg={'white'} m={0} p={[4,4]}>
                <img width={['100%']} src={meetDesktop} alt=""/>
              </Box>
            </Div>
          </Div>

          <Div display={['block','flex']} flexWrap={'wrap'} mt={[6,8]}>
            <Div mb={6} mr={[0,6]} flex={1}>
              <Title mb={3} mr={[4,6]} fontSize={[2,4]}>Gagner du temps</Title>
              <Text fontSize={[2,4]}>Un diagnostic effectué par vidéo permet de diminuer les déplacements et les temps d'attentes.</Text>
            </Div>
            <Div mb={6} mr={[0,6]} flex={1}>
              <Title mb={3} mr={[4,6]} fontSize={[2,4]}>Réduir les contaminations</Title>
              <Text fontSize={[2,4]}>En offrant une partie de vos services en ligne, vous aidez à diminuer les interactions et l'exposition aux germes dans les salles d'attente.</Text>
            </Div>
            <Div mb={6} mr={[0,6]} flex={1}>
              <Title mb={3} mr={[4,6]} fontSize={[2,4]}>Respecter la sphère privée</Title>
              <Text fontSize={[2,4]}>Le système vidéo de Stairlin respecte votre vie privée et celle des autres. Nous assurons une sécurité et une confidentialité optimale.</Text>
            </Div>
          </Div>
        </Div>
      </Div>

      <Div mt={[8]}>
        <TryOrSchedule />
      </Div>

    </Layout>
  )
}
